import Header from './components/Header.js';
import HeroSection from './components/HeroSection.js';

function App() {
  return (
    <div className="bg-white">
      <Header />
      <HeroSection />
    </div>
  );
}

export default App;